.header-container {

    height: 100px;
    width: inherit;
    display: flex;

    vertical-align: middle;

}

.header-container_left  {
    width: 25%;
    height: inherit;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    vertical-align: middle;

    }

.header-container_center  {
    width: 50%;
    height: inherit;
    }

.header-container_right  {
    width: 25%;
    margin-right: 1.5rem;
    height: inherit;

    display: flex;
    flex-direction: row-reverse;
    vertical-align: middle;
    }

.header-container_left img {
    flex: 1;
    width: 120px;
    height: 60px;
    left: 10px;
    top: 20px;
    opacity: 75%;
    margin-left: 1.5rem;
    margin-top: 1.5rem;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.header-container_left h1 {
    flex:2;
    width: 120px;
    height: 50px;
    left: 145px;
    top: 20px;
    opacity: 0.75;
    margin-left: 2rem;

    margin-block-start: 0;
    margin-block-end: 0;
    
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 40.4651px;
    line-height: 55px;
    
    color: #000000;
    

    
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.header-container_right button {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
        
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;
}
.header-container_right button:hover {
       
    background: rgba(56, 56, 56, 0.46);

}

.header-container_right button:active {
       
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);

}

.header-container_left select {
    flex:2;
    box-sizing: border-box;

    width: 144px;
    height: 68px;
    right: 36px;
    top: 30px;
        
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;
}

.header-container_left select:hover {
    background: rgba(56, 56, 56, 0.46);
}
