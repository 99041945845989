.footer-container {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    
    position: absolute;
    bottom: 0;
}

.footer-container_left  {
    width: 30%;
    height: inherit;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    vertical-align: middle;

}

.footer-container_center  {
    width: 30%;
    height: inherit;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    vertical-align: middle;

}

.footer-container_right  {
    width: 40%;
    height: inherit;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    vertical-align: middle;

}

.footer-container_left-previous {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_left-previous:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_left-previous:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}


.footer-container_left-next {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_left-next:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_left-next:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_left-playAll {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_left-playAll:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_left-playAll:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_center {
    flex-direction: row-reverse;
    margin-right: 1.5rem;
  }

.footer-container_center-previous {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_center-previous:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_center-previous:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_center-play {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_center-play:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_center-play:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_center-play-clicked {
    box-sizing: border-box;

    width: 72px;
    height: 68px;
    right: 36px;
    top: 30px;
    
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_center-next {
    box-sizing: border-box;

    width: 72px;
    height: 68px;

    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 1.5rem;
    margin-top: 1rem;

    cursor: pointer;

}

.footer-container_center-next:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_center-next:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_right {
    flex-direction: row-reverse;
    margin-right: 1.5rem;
  }

.footer-container_right-slidecontainer {
    width: 60%;
    vertical-align: middle;
    margin-top: 2.5rem;
    margin-left: 1.5rem;
  }
  
  .footer-container_right-slider {
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .footer-container_right-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #04AA6D;
    cursor: pointer;
  }
  
  .footer-container_right-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }


  .footer-container_right-repeat {
    box-sizing: border-box;

    width: 62px;
    height: 43px;

    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 2.5rem;
    margin-top: 1.5rem;

    cursor: pointer;

}

.footer-container_right-repeat:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_right-repeat:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}

.footer-container_right-repeat-clicked {
    box-sizing: border-box;

    width: 62px;
    height: 43px;

    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    margin-left: 2.5rem;
    margin-top: 1.5rem;

    cursor: pointer;
}

.footer-container_right-speed-container {
    
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column-reverse;
}


.footer-container_right-speed {
    box-sizing: border-box;

    width: 62px;
    height: 43px;


    margin-top: 1.5rem;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    cursor: pointer;

}
.footer-container_right-speed:hover {
    background: rgba(56, 56, 56, 0.46);
}

.footer-container_right-speed:active {
    background: rgba(56, 56, 56, 0.46);
    transform: translateY(4px);
}


.footer-container_right-speed-dropdown {
    display: none;

    margin-bottom: 1.5rem;
    bottom: 0;
    position: absolute;

    align-content: center;

    z-index: 1;

    width: 62px;
    
    background: rgba(244, 244, 244, 0.46);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 14px;

    cursor: pointer;

}

.footer-container_right-speed-dropdown p {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    text-align: center;

}

    